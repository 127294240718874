import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useEthers, shortenAddress } from '@usedapp/core';
import ConnectWalletBtn from '../components/ConnectWalletBtn';


const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
        appBar: {
            backgroundColor: "rgb(50,50,50)",
            boxShadow: "None",
            paddingBottom: theme.spacing(1)
        },
        logo: {
            height: 'auto',
            width: '40%'
        },
        whiteText: {
          color: "#ffffff"
        },
        goldText: {
          color: "#ffe699"
        },
        greyText: {
          color: "#a6a6a6"
        },
        greenText: {
          color: "#50C878"
        },
        purpleText: {
          color: "rgb(164, 168, 255)"
        },
        buttonMargin: {
            marginTop: theme.spacing(3)
          },
        btnColor: {
          color: "#ffe699",
          backgroundColor: btnColor,
          "&:hover": {
              color: "rgb(50,50,50)",
              backgroundColor:" #ffe699",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: btnColor
            }
          }
        },
    }
});

function Navbar() {
    const classes = useStyles();

    return (
        <div >
            <AppBar className={ classes.appBar } position="relative">
                <Toolbar>
                <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item lg={2}>
                    <Box mt={2}>
                      <Button className={ classes.btnColor } variant="contained" href='https://discord.gg/qeV5vdWvv5' target="_blank" size='large'>
                          <strong>DISCORD LINK</strong>
                      </Button>
                    </Box>

                  </Grid>

                <Grid item lg={2}>
                    <Box mt={2}>
                      <ConnectWalletBtn classes={classes}/>
                    </Box>

                  </Grid>
                  

                </Grid>
             
                </Toolbar>
            </AppBar>
        </div>    
    );
  }
  
export default Navbar;

