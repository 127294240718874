import Main from "./pages/Main.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {DAppProvider, ChainId} from '@usedapp/core';


function App() {
  return (
    <DAppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </Router>
    </DAppProvider>
    
    
  );
}

export default App;
