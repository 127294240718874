import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
          backgroundColor: "rgb(50,50,50)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      greenText: {
        color: "#50C878"
      },
      redText: {
        color: "#FF0000"
      },
      purpleText: {
        color: "rgb(164, 168, 255)"
      },
      secondaryText: {
        color: "rgb(164, 158, 189)"
      },

      card: {
        backgroundColor: "rgb(40,40,40)"
      },

      containerMt: {
        marginTop: "3rem"
      },
      btnColor: {
        color: "#ffe699",
        backgroundColor: btnColor,
        "&:hover": {
            color: "rgb(50,50,50)",
            backgroundColor:" #ffe699",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },
    }
});

function Reasons() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column" justifyContent="center" alignItems='center' alignContent='center'>
                

                <Grid container item className={classes.containerMt}>
                  <Grid container justifyContent='space-evenly' spacing={5}>
                    <Grid item md={4}>
                      <Card className={classes.card}>
                        <CardHeader
                          
                          title=<Typography className={classes.goldText} variant='h5'>
                            <strong>HOW TO MINT AN NFT</strong> 
                        </Typography>
                        />
                        <CardContent>
                        <List>
                        <ListItem>
                            <Typography className={classes.whiteText} variant='h6'>
                              1. Click on 'CONNECT WALLET' at the top of the page.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography className={classes.whiteText} variant='h6'>
                              2. Ensure your MetaMask wallet is on the Binance Smart Chain.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography className={classes.whiteText} variant='h6'>
                              3. Click on the 'APPROVE 100 BUSD' button.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography className={classes.whiteText} variant='h6'>
                              4. Click on the 'MINT' button.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography className={classes.greenText} variant='subtitle1'>
                              <strong>Congratulations! You now own a YEAR 1 AI-KEY NFT.</strong> 
                            </Typography>
                          </ListItem>
                        </List>
                        </CardContent>

                      </Card>


                    </Grid>

                    <Grid item md={4}>
                      <Card className={classes.card}>
                        <CardHeader
                          title=<Typography className={classes.goldText} variant='h5'>
                            <strong>YEAR 1 AI-KEY NFT BENEFITS</strong> 
                        </Typography>
                        />
                        <CardContent>
                          <List>
                            <ListItem>
                              <Typography className={classes.whiteText} variant='h6'>
                                1. Access to the AI-LIMITLESS private discord until December 31, 2023.
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography className={classes.whiteText} variant='h6'>
                                2. Discount on the Year 2 AI-LIMITLESS NFT.
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography className={classes.whiteText} variant='h6'>
                                3. Potential airdrops for the AI-LIMITLESS Coin (coming in Year 2).
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Typography className={classes.redText} variant='subtitle1'>
                                <strong>Spots are limited to the maxiumum number of NFTs which can be minted.</strong> 
                              </Typography>
                            </ListItem>

                      
                          </List>
                        </CardContent>

                      </Card>


                    </Grid>

                  </Grid>


                </Grid>

                <Grid container item className={classes.containerMt}>
                  <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                    <Grid item xs={12}>
                      <Typography className={classes.whiteText} variant='h6' align='center'>
                          <strong>JOIN THE AI-LIMITLESS DISCORD NOW TO LEARN MORE!</strong> 
                      </Typography>

                    </Grid>

                    <Box mt={2}>
                      <Button className={ classes.btnColor } variant="contained" href='https://discord.gg/qeV5vdWvv5' target="_blank" size='large'>
                          <strong>DISCORD LINK</strong>
                      </Button>
                    </Box>

                  </Grid>


                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Reasons;