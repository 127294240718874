import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../sections/Navbar';
import Info from '../sections/Info';
import MintNFT from '../sections/MintNFT';
import Reasons from '../sections/Reasons';
import Disclaimer from '../sections/Disclaimer';

const useStyles = makeStyles((theme) => {
    return {
      root: {
          flexGrow: 1
      }
    }
  });
  
  function Main() {
      const classes = useStyles();
      return (
          <div className={ classes.root }>
           <Navbar/>
           <Info/>
           <MintNFT/>
           <Reasons/>
           <Disclaimer/>
          </div>    
      );
    }
    
  export default Main;