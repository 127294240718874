import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";

import GEMKEYY1 from '../abi/GEMKEYY1.json';
import BEP20Token from '../abi/BEP20Token.json';

export const bep_20_abi = BEP20Token.abi
export const bep_20_address = "0xe9e7cea3dedca5984780bafc599bd69add087d56" 
export const bep_20_interface = new utils.Interface(bep_20_abi)
export const bep_20_contract = new Contract(bep_20_address, bep_20_interface)

export const gemkeyy1_abi = GEMKEYY1.abi
export const gemkeyy1_address = "0x10a6f51a5ba64f3a2671ab7049ef88a45980a0d9" 
export const gemkeyy1_interface = new utils.Interface(gemkeyy1_abi)
export const gemkeyy1_contract = new Contract(gemkeyy1_address, gemkeyy1_interface)
