import { useEthers, useContractFunction, useCall, useTokenBalance, useContractCall } from "@usedapp/core";
import { constants, utils, BigNumber } from "ethers";

import {
  bep_20_contract,

  gemkeyy1_address,
  gemkeyy1_contract,
  gemkeyy1_interface

} from '../constants/contracts';



export function useApproveBUSD(){
    const { state, send, events, resetState } = useContractFunction(bep_20_contract, "approve");
    return { state, send, events, resetState };
}


export function useMint(){
  const { state, send, events, resetState } = useContractFunction(gemkeyy1_contract, "safeMint");
  return { state, send, events, resetState };
}

export function useViewTokenCount() {
    const [tokenCount] = useContractCall({
      abi: gemkeyy1_interface,
      address: gemkeyy1_address,
      method: "viewTokenCount",
      args: [], 
    }) ?? [];
    
    return tokenCount;
  }

export function useOwnsNFT() {
  const { account } = useEthers();
  const [ownsNft] = useContractCall({
    abi: gemkeyy1_interface,
    address: gemkeyy1_address,
    method: "ownsNFT",
    args: [account], 
  }) ?? [];
  
  return ownsNft;
}