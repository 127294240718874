import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "rgb(40, 40, 40)",
          padding: theme.spacing(4, 8, 6),
          color: "#a6a6a6"
      }
    }
});

function Disclaimer() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
                <Typography variant='body2'>© AI-LIMITLESS 2023</Typography>
                <Typography variant='body2'>Disclaimer: AI-LIMITLESS, and any information presented herein, is for educational use only and should not be considered as financial advice.</Typography>
            </Grid>
        </div>    
    );
  }
  
export default Disclaimer;